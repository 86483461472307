<div *ngIf="isLoading" class="loading-spinner">
    <app-spinner></app-spinner>
</div>

<app-custom-alert [message]="alertMessage" [isVisible]="showCustomAlert"></app-custom-alert>

<div *ngIf="!isLoading">
    <div *ngFor="let data of deviceStatisticalData">
        <app-device-item
            [device]="data.device"
            [lastObservationFecha]="data.lastObservation"
            [lastObservation]="data.lastKnownObservation[data.device.id]"
            [statisticals]="data.statisticals"
            [mapStatistical]="mapStatistical"
            [init_yyyymmdd]="init_yyyymmdd"
            [end_yyyymmdd]="end_yyyymmdd"
            (centerMapEvent)="centerMap($event)"
            (viewRouteEvent)="viewRoute($event)"
            (clearMapEvent)="clearMap()"
            (toggleEvent)="onDeviceItemToggle($event)"
        ></app-device-item>
    </div>
    <div *ngIf="isLoadingRoute" class="loading-spinner">
        <app-spinner></app-spinner>
    </div>
</div>