import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; // Asegúrate de importar FormsModule

import { appRoutes } from './app.routes';
import { AuthService } from './core/services/auth.service';
import { AuthGuard } from './core/guards/auth.guard';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    importProvidersFrom(BrowserModule, FormsModule), // Asegúrate de importar FormsModule aquí
    provideHttpClient(withInterceptorsFromDi()), 
    AuthService,
    AuthGuard, provideAnimationsAsync(), provideAnimationsAsync(), provideAnimationsAsync()
  ]
};
