<div class="modal" [ngClass]="{'show': true}" (click)="closeModal()">
    <div class="modal-content" (click)="$event.stopPropagation()">
        <div class="modal-header">
            <span class="close" (click)="closeModal()">&times;</span>
            <h2>Fotos del dispositivo : {{ deviceId }}</h2>
        </div>
        <div class="modal-body">
            <div *ngIf="observations.length === 0" class="no-observations">
                <p>No hay observaciones con fotos para este dispositivo.</p>
            </div>
    
            <div *ngFor="let observation of observations" class="observation" (click)="openPhotos(observation)">
                <div class="observation-header">
                    <i class="material-icons action-icon">photo</i>
                    <div class="observation-info">
                    <h3>Observación {{ observation.id }}</h3>
                    <p>Fecha: {{ observation.registeredAtHumanFormat }}</p>
                    </div>
                    <span class="total-images">{{ observation.images.length }} imágenes totales</span>
                    <i *ngIf="observation.values[3] !== 0 && observation.values[4] !== 0" class="material-icons action-icon">
                        location_on
                    </i>
                </div>
                <div *ngIf="observation.images.length === 0" class="observation-images">
                    <p>No hay imágenes para esta observación</p>
                </div>
                <div *ngIf="observation.images.length >= 1" class="observation-images">
                    <img 
                    *ngFor="let image of observation.images" 
                    [src]="endpoint+deviceId+'/'+observation.id+'/'+image" 
                    [alt]="image"
                    (error)="onImageError($event)">
                </div>
            </div>
        </div>
    </div>

    <!-- Visualizador de imágenes -->
    <app-image-viewer 
        *ngIf="selectedObservation && isPhotoViewerOpen" 
        [selectedObservation] = "selectedObservation"
        [device]="deviceId.toString()" 
        [observation]="selectedObservation.id.toString()" 
        [images]="selectedObservation.images" 
        (closeViewer)="closeImageViewer()">
    </app-image-viewer>
</div>