export class Observation {
    id: number;
    registeredAt: number;
    registeredAtHumanFormat: string;
    takenAt: number;
    correlative: number;
    values: { [key: number]: any };
    images: string[];
    hasImages: boolean;
    zoneId: string;
    year: number;
    month: number;
    day: number;

    constructor(
        id: number,
        registeredAt: number,
        registeredAtHumanFormat: string,
        takenAt: number,
        correlative: number,
        values: { [key: number]: any },
        images: string[],
        hasImages: boolean,
        zoneId: string,
        year: number,
        month: number,
        day: number
    ) {
        this.id = id;
        this.registeredAt = registeredAt;
        this.registeredAtHumanFormat = registeredAtHumanFormat;
        this.takenAt = takenAt;
        this.correlative = correlative;
        this.values = values;
        this.images = images;
        this.hasImages = hasImages;
        this.zoneId = zoneId;
        this.year = year;
        this.month = month;
        this.day = day;
    }

    static fromJson(json: any): Observation {
        return new Observation(
            json.id,
            json.registeredAt,
            json.registeredAtHumanFormat,
            json.takenAt,
            json.correlative,
            json.values,
            json.images,
            json.hasImages,
            json.zoneId,
            json.year,
            json.month,
            json.day
        );
    }
}
