import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    standalone: true,
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    imports: [CommonModule, FormsModule, RouterModule]
})
export class LoginComponent {
    username: string = '';
    password: string = '';
    errorMessage: string = '';  

    constructor(private authService: AuthService, private router: Router) { }

    onLogin(): void {
        console.log('Login clicked');
        this.playSilentAudio();
        console.log('Username: ' + this.username);
        this.authService.login(this.username, this.password).subscribe({
            next: () => {
                this.router.navigate(['/dashboard']);
                this.errorMessage = ''; 
            },
            error: (error: HttpErrorResponse) => {
                this.errorMessage = 'Nombre de usuario o contraseña incorrecta.'; 
            }
        });
    }

    playSilentAudio() {
        const silentAudio = new Audio('../../assets/sound/silent-audio.mp3');
        silentAudio.play().catch((error) => {
            console.log('Silent audio play failed: ', error);
        });
    }
}