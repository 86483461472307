<div class="modal" [ngClass]="{'show': true}" (click)="close()">
    <div class="modal-content" (click)="$event.stopPropagation()">
        <div class="modal-header">
            <span class="close" (click)="close()">&times;</span>
            <h2>Gráficos del dispositivo : {{ deviceId }}</h2>
        </div>
        <div class="modal-body">
            <div class="chart-container">
                <app-line-chart
                [title]="'Grafico de temperatura y humedad'"
                [xData]="xDataTemperature"
                [datasets]="datasets"
                ></app-line-chart>
            </div>
        </div>
    </div>
</div>