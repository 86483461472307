import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-openings-list',
    standalone: true,
    imports: [ CommonModule ],
    templateUrl: './openings-list.component.html',
    styleUrl: './openings-list.component.scss'
})
export class OpeningsListComponent implements OnInit{
    @Input() dates: string[] = [];
    @Input() observationNumbers: number[] = [];

    public doorOpenings: { date: string, observationNumber: number }[] = [];

    constructor() { }

    ngOnInit(): void {
        this.updateDoorOpenings();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['dates'] || changes['observationNumbers']) {
            this.updateDoorOpenings();
        }
    }

    private updateDoorOpenings(): void {
        this.doorOpenings = this.dates.map((date, index) => ({
            date: date,
            observationNumber: this.observationNumbers[index]
        }));
    }
}