<div class="modal" [ngClass]="{'show': true}" (click)="close()">
    <div class="modal-content" (click)="$event.stopPropagation()">
        <div class="modal-header">
            <span class="close" (click)="close()">&times;</span>
            <h2>Lista de aperuturas dispositivo {{ deviceId }}</h2>
        </div>
        <div class="modal-body">
            <div>
                <app-openings-list [dates]="xDataLuminosity" [observationNumbers]="observationIds" ></app-openings-list>
            </div>
        </div>
    </div>
</div>