import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    private apiUrl: string;

    constructor(private http: HttpClient, private router: Router) { 
        // Define las diferentes URLs
        const devUrl = 'http://localhost:8080/ws/mab_web_service/';
        const testUrl = 'https://www.test.polizone.mab.cl/ws/mab_web_service/';
        const prodUrl = 'https://www.polizone.mab.cl/ws/mab_web_service/';

        // Elige la URL apropiada, aquí podrías hacer una selección basada en el entorno
        this.apiUrl = prodUrl; // Cambia a `devUrl` o `testUrl` según sea necesario
    }

    getDevices(token: string): Observable<any> {
        const body = {
            url: this.apiUrl,
            type: 'get_device_ids',
            tkn: token
        };

        return this.http.post<any>(this.apiUrl, body).pipe(
            tap(res => {
                if (res.error_code == 401) {
                console.log('Token expired');
                localStorage.removeItem('token');
                this.router.navigate(['/login']);
                }else{
                    const devices = res.devices;
                    const non_selected_devices = res.non_selected_devices;
                    localStorage.setItem('devices', JSON.stringify(devices));
                    localStorage.setItem('non_selected_devices', JSON.stringify(non_selected_devices));
                }
            }),
            catchError(this.handleError<any>('getDevices', []))
        );
    }

    pushDevice(token: string, selectedDevices: Map<string, boolean>): Observable<any> {
        console.log('pushDevice', selectedDevices);
        const selection = Object.fromEntries(selectedDevices);
        const body = {
            url: this.apiUrl,
            type: 'track_devices',
            tkn: token,
            selection: selection
        };

        return this.http.post<any>(this.apiUrl, body).pipe(
            tap(res => {
                if (res.error_code == 401) {
                console.log('Token expired');
                localStorage.removeItem('token');
                this.router.navigate(['/login']);
                }
            }),
            catchError(this.handleError<any>('pushDevice', []))
        );
    }

    getObservations(token: string, trackerDeviceId: number, statisticalId: number, observationId: number, init_yyyymmdd: number, end_yyyymmdd: number, offset: number): Observable<any> {
        const body = {
            url: this.apiUrl,
            type: 'get_observations',
            tkn: token,
            deviceId: trackerDeviceId,
            statisticalId: statisticalId,
            observationId: observationId,
            pageSize: 1000,
            offset: offset,
            init_yyyymmdd: init_yyyymmdd,
            end_yyyymmdd: end_yyyymmdd
        };
        return this.http.post<any>(this.apiUrl, body).pipe(
            catchError(this.handleError<any>('getObservations', []))
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }
}