<div class="image-viewer-backdrop" (click)="handleCloseViewer($event)">
    <div class="image-viewer-content" (click)="$event.stopPropagation()">
        <span class="close" (click)="handleCloseViewer($event)">&times;</span>
        <div class="image-map-container">
            <div class="image-container">
                <img [src]="endpoint+device+'/'+observation+'/'+currentImage" [style.transform]="transform" >
            </div>
            <div *ngIf="showMap" class="map-container">
                <app-map-component [latLng]="latLng"></app-map-component>
            </div>
        </div>

        <div class="image-viewer-controls">
            <button (click)="previousImage()"><i class="material-icons">navigate_before</i></button>
            <button (click)="nextImage()"><i class="material-icons">navigate_next</i></button>
            <button (click)="rotateImage()"><i class="material-icons">rotate_right</i></button>
            <button (click)="zoomIn()"><i class="material-icons">zoom_in</i></button>
            <button (click)="zoomOut()"><i class="material-icons">zoom_out</i></button>
        </div>
    </div>
</div>