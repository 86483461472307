import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, OnDestroy, Output, QueryList, ViewChildren, Input, SimpleChanges } from '@angular/core';
import { DeviceService } from '../../core/services/device.service';
import { TrackerDevice } from '../../core/models/tracker-device.model';
import { Observation } from '../../core/models/observation.model';
import { Statistical } from '../../core/models/statistical.model';
import { DeviceItemComponent } from '../../core/components/device-item/device-item.component';
import { SpinnerComponent } from '../../core/components/spinner/spinner.component';
import { CustomAlertComponent } from '../../core/components/custom-alert/custom-alert.component';
import { Router } from '@angular/router';
import { Subscription, interval, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-device-list',
    standalone: true,
    imports: [CommonModule, DeviceItemComponent, SpinnerComponent, CustomAlertComponent],
    templateUrl: './device-list.component.html',
    styleUrls: ['./device-list.component.scss']
})
export class DeviceListComponent implements OnInit, OnDestroy {
    devices: TrackerDevice[] = [];
    previousDevices: TrackerDevice[] = [];
    mapStatistical: { [key: number]: Statistical } = {};
    previousMapStatistical: { [key: number]: Statistical } = {};
    observationsByDevice: { [key: number]: Observation[] } = {};
    previousObservationsByDevice: { [key: number]: Observation[] } = {};
    realDeviceStatisticalData: any[] = [];
    deviceStatisticalData: any[] = [];
    isLoading: boolean = false;
    isLoadingRoute: boolean = false;
    showCustomAlert: boolean = false;
    alertMessage: string = '';
    isFirstLoad: boolean = true;  // Nueva bandera para controlar la primera carga
    lastKnownObservations: { [key: number]: Observation } = {};

    pollingSubscription: Subscription | null = null;
    pollingInterval: number = 15000; 

    @Input() init_yyyymmdd = -1;
    @Input() end_yyyymmdd = -1;

    @Output() centerMapEvent = new EventEmitter<{ lat: number, lng: number, title: String, selectedObservation: Observation}>();
    @Output() drawRouteEvent = new EventEmitter<{ lat: number, lng: number, title: String, selectedObservation: Observation}[]>();
    @Output() clearMapEvent = new EventEmitter<void>();
    @Output() devicesLoadedEvent = new EventEmitter<{ lat: number, lng: number, title: String, selectedObservation: Observation}[]>();

    @ViewChildren(DeviceItemComponent) deviceItems!: QueryList<DeviceItemComponent>;

    openDeviceId: number | null = null;

    constructor(private deviceService: DeviceService, private router: Router) {}

    ngOnInit(): void {
        const token = localStorage.getItem('token');
        if (token) {
            this.startPolling(token);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['init_yyyymmdd'] || changes['end_yyyymmdd']) {
            this.onFiltersApplied();
        }
    }

    onFiltersApplied(): void {
        if(this.init_yyyymmdd === -1 || this.end_yyyymmdd === -1) {
            return;
        }
        console.log('Filtros aplicados:', this.init_yyyymmdd, this.end_yyyymmdd);
        console.log(this.deviceStatisticalData);
        
    }

    startPolling(token: string): void {
        this.isLoading = true; // Mostrar el spinner al iniciar la carga
    
        this.pollingSubscription = timer(0, this.pollingInterval).pipe(
            switchMap(() => this.deviceService.getDevices(token))
        ).subscribe(
            response => {
                this.isLoading = false; // Ocultar el spinner cuando se completó la carga
                if (response.error_code === 401) {
                    console.log('Token expired');
                    localStorage.removeItem('token');
                    this.router.navigate(['/login']);
                    return;
                }
    
                if (this.isFirstLoad) {
                    // Si es la primera carga, simplemente actualizar datos sin mostrar la alerta
                    this.updateData(response, true);
                    this.isFirstLoad = false;  
                } else {
                    const changedDevice = this.detectChanges(response);
                    if (changedDevice) {
                        const registeredAtChanged = this.detectRegisteredAtChanges(response);
                        const locationChangedDeviceId = this.detectLocationChanges(response);

                        if(registeredAtChanged) {
                            // Actualiza la data y da la alertar 
                            this.showAlert(`Dispositivo Nº: ${changedDevice.id} - ABIERTO`);
                            this.clearMap();
                            this.updateData(response, false); 
                        } else if (locationChangedDeviceId) {
                            // Actualiza la data sin alertar 
                            this.updateData(response, false); 
                        }
                        
                    } 
    
                }
            },
            error => {
                this.isLoading = false; // Asegurarse de ocultar el spinner en caso de error
                console.error('Error en el polling:', error);
            }
        );
    }

    detectRegisteredAtChanges(response: any): boolean {
        //Recorremos las data de los dispositivos
        for (const deviceId in response.observationsByDevice) {
            //Necesitamos convertir el ID a número
            const deviceIdNumber = parseInt(deviceId, 10);

            //Obtenemos las observaciones anteriores y actuales
            const previousObservation = this.previousObservationsByDevice[deviceIdNumber];
            const currentObservation = response.observationsByDevice[deviceId];
            
            //Si no hay observaciones anteriores o actuales, continuamos con la siguiente iteración
            if (!previousObservation || previousObservation.length === 0 || currentObservation.length === 0) {
                continue;
            }
            
            //Obtenemos la fecha de registro de la ultima observacion anterior y actual
            const previousRegiteredAt = previousObservation[0].registeredAt;
            const currentRegisteredAt = currentObservation[0].registeredAt;
            
            // Quiere decir que cambio la fecha de registro    
            if (previousRegiteredAt !== currentRegisteredAt) {
                return true; 
            }
        }
        //No hay cambios
        return false;
    }

    detectLocationChanges(response: any): number | null {
        for (const deviceId in response.observationsByDevice) {
            const deviceIdNumber = parseInt(deviceId, 10);

            const previousObservation = this.previousObservationsByDevice[deviceIdNumber];
            const currentObservation = response.observationsByDevice[deviceId];
            
            if (!previousObservation || previousObservation.length === 0 || currentObservation.length === 0) {
                continue;
            }
    
            const previousLat = previousObservation[0].values[3];
            const previousLng = previousObservation[0].values[4];
            const currentLat = currentObservation[0].values[3];
            const currentLng = currentObservation[0].values[4];
    
            if (previousLat !== currentLat || previousLng !== currentLng) {
                // Retorna el ID del dispositivo que cambió de posición
                return parseInt(deviceId, 10); 
            }
        }
        
        // No se detectaron cambios en las posiciones
        return null; 
    }

    detectChanges(response: any): TrackerDevice | null {
        // Comparar observationsByDevice
        for (const deviceIdString in response.observationsByDevice) {
            const deviceId = Number(deviceIdString); // Convertir a Number
            const currentObservation = response.observationsByDevice[deviceId];
            const previousObservation = this.previousObservationsByDevice[deviceId];

            if (!previousObservation || JSON.stringify(previousObservation) !== JSON.stringify(currentObservation)) {
                // Encuentra el dispositivo correspondiente
                const changedDevice = response.devices.find(
                    (device: TrackerDevice) => device.id === deviceId
                );

                if (changedDevice) {
                    return changedDevice;
                }
            }
        }

        /*for (let i = 0; i < response.devices.length; i++) {
            if (this.previousDevices.length === 0 || 
                this.previousDevices.length !== response.devices.length || 
                JSON.stringify(response.devices[i]) !== JSON.stringify(this.previousDevices[i])) {
                
                return response.devices[i]; // Retorna el dispositivo que ha cambiado
            }
        }*/
    
        return null; // Si no hay cambios
    }

    updateData(response: any, isFirstLoad: boolean): void {
        // Actualizar dispositivos y otros datos
        this.devices = response.devices;
        this.mapStatistical = response.mapStatistical;
        this.observationsByDevice = response.observationsByDevice;
        this.lastKnownObservations = response.lastKnownPositionByDevice;
        this.processDeviceData();

        let lastKnownDevices = response.lastKnownPositionByDevice;

        if(isFirstLoad) {
            const positions = this.devices.map(device => {
                const observation = lastKnownDevices[device.id];
                return observation ? { lat: observation.values[3], lng: observation.values[4], title: observation.id.toString() + ";" + observation.registeredAtHumanFormat + ";" + device.id.toString(), selectedObservation: observation } : null;
            }).filter(position => position !== null && position.lat !== 0 && position.lng !== 0) as unknown as { lat: number, lng: number, title: String, selectedObservation: Observation }[];
            this.devicesLoadedEvent.emit(positions);
        }else{
            const firstPositionArray = this.devices.map(device => {
                const observation = lastKnownDevices[device.id];
                return observation ? { lat: observation.values[3], lng: observation.values[4], title: observation.id.toString() + ";" + observation.registeredAtHumanFormat + ";" + device.id.toString(), selectedObservation: observation } : null;
            }).find(position => position !== null && position.lat !== 0 && position.lng !== 0);
            
            const result = firstPositionArray ? [firstPositionArray] : [] as { lat: number, lng: number, title: String, selectedObservation: Observation }[];
    
            this.devicesLoadedEvent.emit(result);
        }
        // Guardar el nuevo estado como anterior
        this.previousDevices = [...response.devices];
        this.previousMapStatistical = { ...response.mapStatistical };
        this.previousObservationsByDevice = { ...response.observationsByDevice };
    }

    showAlert(message: string): void {
        this.alertMessage = message;
        this.showCustomAlert = true;

        const audio = new Audio('../../assets/sound/mixkit-bell-notification-933.wav');
        audio.preload = 'auto'; // Asegurar que el archivo esté cargado
    
        const playPromise = audio.play();
    
        if (playPromise !== undefined) {
            playPromise
                .then(() => {
                    console.log('Audio is playing');
                })
                .catch((error) => {
                    console.error('Audio play failed:', error);
                    setTimeout(() => {
                        audio.play().catch((error) => {
                            console.error('Retry audio play failed:', error);
                        });
                    }, 1000); // Reintentar después de 1 segundo
                });
        }


        // Ocultar la alerta después de unos segundos
        setTimeout(() => {
            this.showCustomAlert = false;
        }, 60000);
    }

    processDeviceData(): void {
        this.deviceStatisticalData = [];
        for (let device of this.devices) {
            let observations: Observation[] = this.observationsByDevice[device.id] || [];
            let lastObservation: Observation | undefined = observations.length > 0 ? observations[0] : undefined;
            let statisticals: Statistical[] = [];
            let lat: number | null = null;
            let lng: number | null = null;

            if (lastObservation) {
                lat = lastObservation.values[3];
                lng = lastObservation.values[4];
            }

            for (let key in this.mapStatistical) {
                let statisticalId = parseInt(key);
                if (device.statisticalHardware[statisticalId]) {
                    let statistical = this.mapStatistical[statisticalId];
                    if (statistical.name !== 'latitude' && statistical.name !== 'longitude') {
                        statisticals.push(statistical);
                    }
                }
            }

            this.deviceStatisticalData.push({
                device: device,
                lastKnownObservation: this.lastKnownObservations,
                lastObservation: lastObservation,
                statisticals: statisticals,
                latitude: lat !== undefined ? lat : 0,
                longitude: lng !== undefined ? lng : 0,
            });
        }
    }

    centerMap(event: { lat: number, lng: number, title: String, selectedObservation: Observation }): void {
        if (event.lat !== 0 && event.lng !== 0 && event.lat !== undefined && event.lng !== undefined) {
            this.centerMapEvent.emit(event);
        }
    }

    async viewRoute(deviceId: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let observations: Observation[] = [];
        let offset = 0;
        this.isLoadingRoute = true;
        while (true) {
            const response = await this.deviceService.getObservations(token, deviceId, 3, -1, this.init_yyyymmdd, this.end_yyyymmdd, offset).toPromise();

            const observationsList: any[] = response.data;
            if (observationsList.length === 0) {
                break;
            }

            observations = observations.concat(observationsList.map(observation => Observation.fromJson(observation)));
            offset += 1000;
        }
        this.isLoadingRoute = false;
        const routeCoordinates = observations.map(observation => ({
            lat: observation.values[3],
            lng: observation.values[4],
            title: observation.id.toString() + '; ' + observation.registeredAtHumanFormat + ';' + deviceId,
            selectedObservation: observation
        })).filter(coord => coord.lat !== null && coord.lng !== null);
        this.drawRouteEvent.emit(routeCoordinates);
    }

    clearMap(): void {
        this.clearMapEvent.emit();
    }

    onDeviceItemToggle(event: { deviceId: number, isOpen: boolean }) {
        if (event.isOpen) {
            if (this.openDeviceId && this.openDeviceId !== event.deviceId) {
                this.closeOpenDevice();
            }
            this.openDeviceId = event.deviceId;
        } else {
            this.openDeviceId = null;
        }
    }

    closeOpenDevice() {
        this.deviceItems.forEach((item) => {
            if (item.device.id === this.openDeviceId) {
                item.closeDropdown();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.pollingSubscription) {
            this.pollingSubscription.unsubscribe(); // Detiene el polling cuando se destruye el componente
        }
    }
}