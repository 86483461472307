<div class="filter-modal-backdrop" (click)="closeModal()">
    <div class="filter-modal-content" (click)="$event.stopPropagation()">
        <h3>Filtrar por fecha:</h3>
        <label>
            Fecha de inicio:
            <input type="date" [(ngModel)]="filters.startDate" />
        </label>
        <label>
            Fecha de fin:
            <input type="date" [(ngModel)]="filters.endDate" />
        </label>

        <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>

        <div class="filter-modal-buttons">
            <button class="apply-button" (click)="applyFilters()">Aplicar</button>
            <button class="cancel-button" (click)="closeModal()">Cancelar</button>
        </div>
    </div>
</div>