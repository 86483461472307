import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Observation } from '../../models/observation.model';
import { MapComponentComponent } from '../map-component/map-component.component';

@Component({
    selector: 'app-image-viewer',
    standalone: true,
    imports: [CommonModule, MapComponentComponent],
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent {
    @Input() selectedObservation: Observation | null = null;
    @Input() images: string[] = [];
    @Input() device: string = '';
    @Input() observation: string = '';
    @Output() closeViewer = new EventEmitter<void>();
    latLng!: { lat: number; lng: number };
    showMap: boolean = false;

    ngOnInit(){
        this.latLng = { lat: this.selectedObservation?.values[3], lng: this.selectedObservation?.values[4] };
        this.showMap = this.latLng.lat !== 0 && this.latLng.lng !== 0;
    }

    isDebug = false;
    isProduction = true;
    urls = {
        debug: 'http://localhost:8080',
        test    : 'https://www.test.polizone.mab.cl',
        production: 'https://www.polizone.mab.cl'
    };
    url = this.isDebug ? this.urls.debug : this.isProduction ? this.urls.production : this.urls.test;
    path = '/images/polizone/files/dtp/'

    endpoint = this.url + this.path;

    currentIndex = 0;
    rotation = 0;
    scale = 1;
    isDragging = false;
    startX = 0;
    startY = 0;
    translateX = 0;
    translateY = 0;
    lastTranslateX = 0;
    lastTranslateY = 0;

    get currentImage() {
        return this.images[this.currentIndex];
    }

    get transform() {
        return `rotate(${this.rotation}deg) scale(${this.scale}) translate(${this.translateX}px, ${this.translateY}px)`;
    }

    handleCloseViewer(event: Event) {
        event.stopPropagation(); // Evita que el evento se propague al modal principal
        this.closeViewer.emit();
    }

    rotateImage() {
        this.rotation = (this.rotation + 90) % 360;
        if (this.rotation % 180 === 0) {
            this.scale = 1;
        } else {
            this.scale = Math.min(
                this.imageContainerWidth / this.imageContainerHeight,
                this.imageContainerHeight / this.imageContainerWidth
            );
        }
        this.resetImagePosition();
    }

    zoomIn() {
        this.scale += 0.1;
        this.adjustImageScale();
    }

    zoomOut() {
        if (this.scale > 0.1) {
            this.scale -= 0.1;
            this.adjustImageScale();
        }
    }

    nextImage() {
        if (this.currentIndex < this.images.length - 1) {
            this.currentIndex++;
            this.resetImagePosition();
        }
    }

    previousImage() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
            this.resetImagePosition();
        }
    }

    private get imageContainerWidth(): number {
        const container = document.querySelector('.image-container') as HTMLElement;
        return container ? container.clientWidth : 0;
    }

    private get imageContainerHeight(): number {
        const container = document.querySelector('.image-container') as HTMLElement;
        return container ? container.clientHeight : 0;
    }

    private adjustImageScale() {
        const image = document.querySelector('.image-container img') as HTMLElement;
        if (image) {
            image.style.width = `${this.scale * 100}%`;
            image.style.height = `${this.scale * 100}%`;
        }
    }

    private resetImagePosition() {
        this.translateX = 0;
        this.translateY = 0;
        this.lastTranslateX = 0;
        this.lastTranslateY = 0;
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        if (this.scale > 1) {
            this.isDragging = true;
            this.startX = event.clientX;
            this.startY = event.clientY;
        }
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (this.isDragging) {
            this.translateX = this.lastTranslateX + event.clientX - this.startX;
            this.translateY = this.lastTranslateY + event.clientY - this.startY;
        }
    }

    @HostListener('mouseup')
    onMouseUp() {
        if (this.isDragging) {
            this.isDragging = false;
            this.lastTranslateX = this.translateX;
            this.lastTranslateY = this.translateY;
        }
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        if (this.isDragging) {
            this.isDragging = false;
            this.lastTranslateX = this.translateX;
            this.lastTranslateY = this.translateY;
        }
    }
}