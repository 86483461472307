import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from '../../services/sidebar.service';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
    standalone: true,
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    imports: [CommonModule, RouterModule]
})
export class SidebarComponent {
    isOpen = false;

    constructor(private sidebarService: SidebarService, private authService: AuthService, private router: Router) {}

    ngOnInit(): void {
        this.sidebarService.sidebarOpen$.subscribe(isOpen => {
        this.isOpen = isOpen;
        });
    }

    closeSidebar(): void {
        this.sidebarService.closeSidebar();
    }

    logout(): void {
        this.authService.logout();
        this.closeSidebar();
        this.router.navigate(['/login']);
    }
}
