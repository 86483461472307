<google-map height="100%" width="100%" [center]="center" [zoom]="zoom">
    <map-marker *ngFor="let marker of markers" 
        #markerElem="mapMarker"
        [position]="marker.position"
        [title]="marker.title"
        [options]="marker.options"
        (mapClick)="openInfoWindow(markerElem)"
    ></map-marker>
    <map-info-window #infoWindow>
        <div style="font-size: 14px; color: #333;">
            <h4 style="font-size: 20px;">{{ selectedMarkerTitle || 'Sin número de observación' }}</h4>
            <p>{{ selectedMarkerFecha || 'Sin fecha asociada' }}</p>
            <img [src]="selectedMarkerImage" alt="Observación sin imagen" style="width: 100px; height: auto; border-radius: 5px;">
        </div>
    </map-info-window>
    <map-polyline [path]="path" [options]="polylineOptions"></map-polyline>
</google-map>

