import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';

@Component({
    selector: 'app-map-component',
    standalone: true,
    imports: [GoogleMapsModule, CommonModule],
    templateUrl: './map-component.component.html',
    styleUrls: ['./map-component.component.scss']
})
export class MapComponentComponent implements OnChanges {
    @Input() latLng!: { lat: number; lng: number };  // Entrada para las coordenadas { lat, lng }

    center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };  // Centro del mapa inicializado con valores por defecto
    zoom = 12;  // Nivel de zoom inicial

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['latLng'] && this.latLng) {
        this.center = this.latLng;  // Configura el centro del mapa con las coordenadas proporcionadas
        }
    }
}