<app-navbar></app-navbar>
<div *ngIf="isLoading" class="loading-spinner">
    <app-spinner></app-spinner>
</div>

<div class="profile-container">
    <!-- Sección de Perfil -->
    <div class="profile-card card">
        <div class="profile-image">
            <i class="material-icons" style="font-size: 100px; color: #757575;">person</i>
        </div>
        <div class="profile-details">
            <h2>Mi Perfil</h2>
            <hr>
            <p>{{isLoading ? "" : userInformation.names + " " + userInformation.scndnames}}</p>
            <p>{{isLoading ? "" : userInformation.phone}}</p>
            <p>{{isLoading ? "" : userInformation.email}}</p>
        </div>
    </div>

    <!-- devices.component.html -->
    <div class="xpay-devices-card card">
        <div class="card-header">
            <h2>Todos Los Dispositivos</h2>
        </div>
    
        <mat-list>
            <mat-list-item class="device-item" *ngFor="let device of allDevicesNonRepeat">
                <div matLine class="device-info">
                    <p><strong>ID de Dispositivo:</strong> {{ device.id }}</p>
                </div>
                <button
                    mat-icon-button
                    matListItemMeta
                    (click)="toggleDeviceSelection(device)">
                    <mat-icon [ngClass]="!device.selected ? 'warn-icon' : 'primary-icon'">
                        {{ !device.selected ? '' : 'check' }}
                    </mat-icon>
                </button>
            </mat-list-item>
        </mat-list>
    
        <div class="button-container">
            <button class="apply-changes-button" (click)="applyChanges()">Aplicar Cambios</button>
        </div>
    </div>
    

    <!-- Sección de mis dispositivos -->
    <div class="bills-card card">
        <div class="card-header">
            <h2>Mis Dispositivos</h2>
        </div>
        <div class="bill-list">
            <div class="bill-row" *ngFor="let device of devices">
                <p>Dispositivo {{ device.id }}</p>
                <span
                    class="status-dot"
                    [ngClass]="device.isOpen == 1 ? 'paid' : 'not-paid'">
                </span>
                <span
                    class="status-text"
                    [ngClass]="device.isOpen == 1 ? 'paid' : 'not-paid'">
                    {{ device.isOpen == 1 ? 'Habilitado' : 'Deshabilitado' }}
                </span>
            </div>
        </div>
    </div>

</div>
