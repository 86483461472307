import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMap, GoogleMapsModule, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { title } from 'node:process';
import { Observation } from '../../core/models/observation.model';

@Component({
    standalone: true,
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    imports: [CommonModule, GoogleMapsModule]
})
export class MapComponent {
    @ViewChild(GoogleMap, { static: false })
    map!: GoogleMap;

    @ViewChild(MapInfoWindow, { static: false })
    infoWindow!: MapInfoWindow;

    zoom = 12;
    center: google.maps.LatLngLiteral = { lat: -33.45, lng: -70.65 };
    markers: Array<any> = [];
    path: google.maps.LatLngLiteral[] = [];
    polylineOptions: google.maps.PolylineOptions = {
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
        icons: [{
            icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: 3,
                strokeColor: '#FF0000'
            },
            offset: '0%',
            repeat: '100px'
        }]
    };

    infoContent = '';
    selectedMarkerTitle: string | undefined;
    selectedMarkerFecha: string | undefined;
    selectedMarkerImage: string | undefined;

    constructor() { }

    ngAfterViewInit(): void {
    }

    openInfoWindow(marker: MapMarker) {
        //this.selectedMarkerTitle = marker.getTitle() || 'No Title';
        var content = marker.getTitle()?.split(';');
        this.selectedMarkerTitle = content ? "Observación: " + content[0] : 'No hay número de observación asociado';
        this.selectedMarkerFecha = content ? "Fecha: " + content[1] : 'No hay fecha asociada';
        this.selectedMarkerImage = content ? content[3] : 'No hay imagen asociada';
        if (this.infoWindow) {
            this.infoWindow.open(marker);
        }
    }

    setBounds(path: { lat: number, lng: number }[]): void {
        const bounds = new google.maps.LatLngBounds();
        path.forEach(point => {
            if (point.lat !== 0 && point.lng !== 0 && point.lat !== undefined && point.lng !== undefined){
                bounds.extend(new google.maps.LatLng(point.lat, point.lng));
            }
        });
        this.map.fitBounds(bounds);
    }

    setCenter(lat: number, lng: number, title: String, selectedObservation: Observation | null): void {
        if (lat !== 0 && lng !== 0 && lat !== undefined && lng !== undefined && !Number.isNaN(lat) && !Number.isNaN(lng)) {
            this.clearMarkers();  
            this.center = { lat, lng };
            this.map.panTo(this.center);
            this.addMarker(lat, lng, title, selectedObservation);
        }
    }

    addMarkers(positions: { lat: number, lng: number, title: String, selectedObservation: Observation }[]): void {
        this.clearMarkers();
        positions.forEach(position => {
            this.addMarker(position.lat, position.lng, position.title, position.selectedObservation);
        });
    }

    addMarker(lat: number, lng: number, title: String, selectedObservation: Observation | null): void {
        if(selectedObservation !== null && selectedObservation.images.length > 0) {
            let url = "https://www.polizone.mab.cl";
            let path = "/images/polizone/files/dtp/";
            let endpoint = url + path;
            let deviceId = title.split(";")[2];
            let observationId = selectedObservation.id;
            let image = selectedObservation.images[0];
            let imageUrl = endpoint + deviceId + "/" + observationId + "/" + image;
            title = title + ";" + imageUrl;
        }

        if (lat !== 0 && lng !== 0 && lat !== undefined && lng !== undefined) {
            const marker = {
                position: { lat, lng },
                map: this.map.googleMap,
                title: title,
                options: {animation: google.maps.Animation.DROP}
            };

            this.markers.push(marker);
        }
    }

    addMarkerWithRoute(lat: number, lng: number, title: String, selectedObservation: Observation, isLastPoint: boolean): void {
        if(selectedObservation !== null && selectedObservation.images.length > 0) {
            let url = "https://www.polizone.mab.cl";
            let path = "/images/polizone/files/dtp/";
            let endpoint = url + path;
            let deviceId = title.split(";")[2];
            let observationId = selectedObservation.id;
            let image = selectedObservation.images[0];
            let imageUrl = endpoint + deviceId + "/" + observationId + "/" + image;
            title = title + ";" + imageUrl;
        }

        if (lat !== 0 && lng !== 0 && lat !== undefined && lng !== undefined) {
            const marker = {
                position: { lat, lng },
                //map: this.map.googleMap,
                title: title,
                options: {
                    animation: google.maps.Animation.DROP,
                    icon: isLastPoint ? {
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 10,
                        fillColor: '#6af034', 
                        fillOpacity: 1,
                        strokeWeight: 2,
                        strokeColor: '#ffffff' 
                    } : {
                    }
                }
            };

            this.markers.push(marker);
        }
    }

    clearMarkers(): void {
        /*for (const marker of this.markers) {
            marker.setMap(null);
        }*/
        this.markers = [];
    }

    clearPath(): void {
        this.path = [];
    }

    drawRoute(path: { lat: number, lng: number, title: String, selectedObservation: Observation }[]): void {
        if (path.length === 0) {
            return;
        }

        let fakePath: google.maps.LatLngLiteral[] = [];

        this.clearMarkers();
        this.clearPath();
        this.setCenterWithRoute(path);
        this.setBounds(path);
        this.path = path.filter(p => p.lat !== null && p.lat !== undefined && p.lng !== null && p.lng !== undefined && p.lat !== 0 && p.lng !== 0)
                        .map((p, index, array) => {
                            const isLastPoint = index === array.length - 1;
                            this.addMarkerWithRoute(p.lat, p.lng, p.title, p.selectedObservation, isLastPoint);
                            return { lat: p.lat as number, lng: p.lng as number };
                    });

        if(this.path.length < 1) {
            this.clearPath();
            //TODO si el path no tiene puntos, agregar un punto en el centro de Santiago
/*            const obs: Observation = new Observation(-1, -1, "", -1, -1, {}, [], false, "", -1, -1, -1);
            this.addMarkerWithRoute(-33.45, -70.65, "No hay; Error, observación no presenta ubicaciones registradas", obs, false);
            this.path = [{ lat: -33.45, lng: -70.65 }];
            */
        }
    }

    setCenterWithRoute(path: { lat: number, lng: number, title: String }[]): void {
        path = path.filter(p => p.lat !== null && p.lat !== undefined && p.lng !== null && p.lng !== undefined && p.lat !== 0 && p.lng !== 0);
        path = path.filter((point, index, self) => 
            point.lat !== 0 || point.lng !== 0 &&
            index === self.findIndex(p => p.lat === point.lat && p.lng === point.lng)
        );

        const deg2rad = (deg: number): number => {
            return deg * (Math.PI / 180);
        };

        const rad2deg = (rad: number): number => {
            return rad * (180 / Math.PI);
        };

        let x = 0;
        let y = 0;
        let z = 0;

        path.forEach((point) => {
            const lat = deg2rad(point.lat);
            const lng = deg2rad(point.lng);

            x += Math.cos(lat) * Math.cos(lng);
            y += Math.cos(lat) * Math.sin(lng);
            z += Math.sin(lat);
        });

        const totalPoints = path.length;
        x /= totalPoints;
        y /= totalPoints;
        z /= totalPoints;

        const lng = Math.atan2(y, x);
        const hyp = Math.sqrt(x * x + y * y);
        const lat = Math.atan2(z, hyp);

        const centroid = {
            lat: rad2deg(lat),
            lng: rad2deg(lng),
        };
        
        centroid.lat = Number.isNaN(centroid.lat) ? 0 : centroid.lat;
        centroid.lng = Number.isNaN(centroid.lng) ? 0 : centroid.lng;

        if (centroid.lat !== 0 && centroid.lng !== 0) {
            this.setCenter(centroid.lat, centroid.lng, "", null);
        }
    }
}
