import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarChartComponent } from '../bar-chart/bar-chart.component';
import { LineChartComponent } from '../line-chart/line-chart.component';
import { Observation } from '../../models/observation.model';
import { Statistical } from '../../models/statistical.model';
import { OpeningsListComponent } from "../openings-list/openings-list.component";

@Component({
    selector: 'app-chart-modal',
    templateUrl: './chart-modal.component.html',
    styleUrls: ['./chart-modal.component.scss'],
    standalone: true,
    imports: [CommonModule, BarChartComponent, LineChartComponent, OpeningsListComponent]
})
export class ChartModalComponent {
    @Input() deviceId!: number;
    @Input() observations!: Observation[];
    @Input() mapStatistical!: { [key: number]: Statistical };
    @Output() closeModalEvent = new EventEmitter<void>();

    epochs: number[] = [];

    //StadisticalId = 1
    yDataHumidity: number[] = [];
    xDataHumidity: string[] = [];

    //StadisticalId = 2
    yDataTemperature: number[] = [];
    xDataTemperature: string[] = [];

    datasets: { yData: number[], label: string, backgroundColor?: string, borderColor?: string }[] = [];

    ngOnInit(){
        for( const observation of this.observations){
            this.epochs.push(observation.registeredAt);

            if(observation.values[1] != 0){
                this.yDataHumidity.push(observation.values[1])
                this.xDataHumidity.push(observation.registeredAtHumanFormat);
            }

            if(observation.values[2] != 0){
                this.yDataTemperature.push(observation.values[2])
                this.xDataTemperature.push(observation.registeredAtHumanFormat);
            }
        }
        this.datasets = [
            { yData: this.yDataHumidity, label: 'Humedad (%)', backgroundColor: 'rgba(173,216,230,0.4)', borderColor: 'rgba(173,216,230,0.99)' },
            { yData: this.yDataTemperature, label: 'Temperatura (°C)', backgroundColor: 'rgba(255,182,193,0.4)', borderColor: 'rgba(255,182,193,0.99)' }
        ];
    }

    close(): void {
        this.closeModalEvent.emit();
    }
}