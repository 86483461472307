import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NavbarComponent } from '../../core/components/navbar/navbar.component';
import { SpinnerComponent } from '../../core/components/spinner/spinner.component';
import { ProfileService } from '../../core/services/profile.service';
import { TrackerDevice } from '../../core/models/tracker-device.model';
import { DeviceWithSelection } from '../../core/models/device-with-selection.model';
import { DeviceService } from '../../core/services/device.service';

@Component({
    selector: 'app-profile',
    standalone: true,
    imports: [
        CommonModule, 
        NavbarComponent, 
        SpinnerComponent, 
        MatListModule,
        MatButtonModule,
        MatIconModule
    ],
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
    
    constructor(private profileService: ProfileService, private deviceService: DeviceService) {}

    userInformation: any;
    devices: TrackerDevice[] = [];
    non_selected_devices: TrackerDevice[] = [];
    allDevices: DeviceWithSelection[] = [];
    allDevicesNonRepeat: DeviceWithSelection[] = [];
    selectedDevices: Map<string, boolean> = new Map<string, boolean>(); //Aca va el device_id -> selected
    isLoading: boolean = false;

    ngOnInit() {
        this.init();
    }

    async init() {
        this.isLoading = true;
        const token = localStorage.getItem('token')!;
        const response = await this.profileService.getProfile(token).toPromise();
        this.isLoading = false;
        this.userInformation = response;
        this.devices = JSON.parse(localStorage.getItem('devices')!);
        this.non_selected_devices = JSON.parse(localStorage.getItem('non_selected_devices')!);
        this.devices = this.devices.sort((a, b) => a.id - b.id);
        this.non_selected_devices = this.non_selected_devices.sort((a, b) => a.id - b.id);
        this.allDevices = [
            ...this.devices.map(device => ({ ...device, selected: true })),
            ...this.non_selected_devices.map(device => ({ ...device, selected: false }))
        ];

        this.devices = this.devices.filter(
            (item, index, self) =>
                index === self.findIndex((t) => t.id === item.id)
        );

        this.allDevicesNonRepeat = this.allDevices.filter(
            (item, index, self) =>
                index === self.findIndex((t) => t.id === item.id)
        );
    }

    async applyChanges(){
        //En este punto ya sabemos que dispositivos se han seleccionado y cuales no
        //Lo que pasa es que existe el id y el device_id
        //el id es el que se le muestra al usuario y el verdadero del dispositivo
        // el device_id es un identificador que se penso para ir cambiando el dispositivo por viaje
        //Por lo que un id puede tener mas de un device_id
        //entonces en este punto tenemos los id seleccionados
        //pero no todos los device_id
        //debemos de buscar los device_id de los id seleccionados
        this.selectDevice();

        //En este punto ya tenemos los device_id seleccionados mapeados en selectedDevices para llegar y mandar la peticion
        this.isLoading = true;
        const token = localStorage.getItem('token')!;
        const response = await this.deviceService.pushDevice(token, this.selectedDevices).toPromise();
        if(response.error_code != -1){
            this.processNewDevices();
        }else{
            alert("Error al guardar los cambios");
        }
        this.isLoading = false;
    }

    processNewDevices(): void {
        //Recordemos que tenemos los dispositivos seleccionados y no seleccionados en el local storage
        //por lo que debemos de actualizar los dispositivos seleccionados y no seleccionados
        //para que se muestren en la vista
        this.devices = this.allDevices.filter(device => device.selected);
        this.non_selected_devices = this.allDevices.filter(device => !device.selected);
        localStorage.setItem('devices', JSON.stringify(this.devices));
        localStorage.setItem('non_selected_devices', JSON.stringify(this.non_selected_devices));
        this.cleanSelectedDevices();
        window.location.reload();

    }

    selectDevice(): void {
        let selectedDevices: Map<string, boolean> = new Map<string, boolean>();
        this.allDevicesNonRepeat.forEach(device => {
            selectedDevices.set(device.id.toString(), device.selected);
        });

        this.allDevices.forEach(device => {
            if(selectedDevices.has(device.id.toString())) {
                device.selected = selectedDevices.get(device.id.toString())!;
            }
            this.selectedDevices.set(device.device_id.toString(), device.selected);
        });
    }

    cleanSelectedDevices(): void {
        this.selectedDevices.clear();
    }

    // Método para alternar la selección de un dispositivo
    toggleDeviceSelection(device: DeviceWithSelection): void {
        device.selected = !device.selected;

        if (device.selected) {
            // Mover a dispositivos seleccionados
            this.devices.push(this.removeSelection(device));
            this.non_selected_devices = this.non_selected_devices.filter(d => d.id !== device.id);
        } else {
            // Mover a dispositivos no seleccionados
            this.non_selected_devices.push(this.removeSelection(device));
            this.devices = this.devices.filter(d => d.id !== device.id);
        }
    }

    // Método auxiliar para remover la propiedad 'selected' antes de mover entre arreglos
    private removeSelection(device: DeviceWithSelection): TrackerDevice {
        const { selected, ...rest } = device;
        return rest;
    }
    
}
