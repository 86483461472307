import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { SidebarService } from '../../services/sidebar.service';
import { FilterModalComponent } from '../filter-modal/filter-modal.component';
import { CommonModule } from '@angular/common';
import { FilterService } from '../../models/filter.model';

@Component({
    standalone: true,
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    imports: [SidebarComponent, FilterModalComponent, CommonModule]
})
export class NavbarComponent {

    isFilterModalOpen = false;
    activeFilters: any = { startDate: -1, endDate: -1 };  // Propiedad para almacenar los filtros activos

    constructor(private sidebarService: SidebarService, private authService: AuthService, private router: Router, private filterService: FilterService) {}

    @Output() filtersApplied = new EventEmitter<any>();

    ngOnInit() {
        // Recuperar los filtros activos (si los hay) del servicio
        this.activeFilters = this.filterService.getFilters();
    }

    handleFilters(filters: any) {
        if (filters) {
            this.activeFilters = filters;  // Almacenar los filtros aplicados
            this.filtersApplied.emit(filters);
        }
        this.isFilterModalOpen = false;
    }

    toggleFilterModal() {
        this.isFilterModalOpen = !this.isFilterModalOpen;
    }

    removeFilter() {
        this.activeFilters = { startDate: '', endDate: '' };
        this.filtersApplied.emit({ startDate: -1, endDate: -1 });
        this.filterService.setFilters('-1', '-1');
    }
    
    formatToDisplayDate(dateNumber: number): string {
        const dateString = dateNumber.toString();
        if (dateString.length === 8) {
            const year = dateString.substring(0, 4);
            const month = dateString.substring(4, 6);
            const day = dateString.substring(6, 8);
            return `${day}/${month}/${year}`;
        }
        return dateString;
    }
    

    toggleSidebar(): void {
        this.sidebarService.toggleSidebar();
    }

    logout(): void {
        this.authService.logout();
        this.router.navigate(['/login']);
    }
}
