import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    private filters = {
        startDate: '',
        endDate: ''
    };

    setFilters(startDate: string, endDate: string) {
        this.filters.startDate = startDate;
        this.filters.endDate = endDate;
    }

    getFilters() {
        return this.filters;
    }
}
