import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { Observation } from '../../../core/models/observation.model';

@Component({
    selector: 'app-photo-modal',
    standalone: true,
    imports: [CommonModule, ImageViewerComponent],
    templateUrl: './photo-modal.component.html',
    styleUrls: ['./photo-modal.component.scss']
})
export class PhotoModalComponent {
    @Input() deviceId!: number;
    @Input() observations!: Observation[];
    @Output() closeModalEvent = new EventEmitter<void>();

    isDebug = false;
    isProduction = true;
    urls = {
        debug: 'http://localhost:8080',
        test    : 'https://www.test.polizone.mab.cl',
        production: 'https://www.polizone.mab.cl'
        };
    url = this.isDebug ? this.urls.debug : this.isProduction ? this.urls.production : this.urls.test;
    path = '/images/polizone/files/dtp/'

    endpoint = this.url + this.path;

    selectedObservation: Observation | null = null;
    isPhotoViewerOpen = false;

    openPhotos(observation: Observation) {
        this.selectedObservation = observation;
        this.filterInvalidImages();
        this.isPhotoViewerOpen = true;
        console.log('openPhotos', this.selectedObservation);
    }

    closeModal() {
        if (!this.isPhotoViewerOpen) {
        console.log('closeModal');
        this.closeModalEvent.emit();
        }
    }

    closeImageViewer() {
        console.log('closeImageViewer');
        this.isPhotoViewerOpen = false;
        this.selectedObservation = null;
        console.log('after closeImageViewer', this.selectedObservation);
    }

    onImageError(event: Event): void {
        const element = event.target as HTMLImageElement;
        element.style.display = 'none'; // Oculta la imagen si no existe
    }

    filterInvalidImages() {
        if (this.selectedObservation && this.selectedObservation.images) {
            const baseUrl = `${this.endpoint}${this.deviceId}/${this.selectedObservation.id}/`;
    
            // Verificamos todas las imágenes para ver si son válidas
            const validImages: string[] = [];
            let loadCount = 0;
    
            this.selectedObservation.images.forEach((image) => {
                const imageUrl = baseUrl + image;
                const img = new Image();
    
                img.onload = () => {
                    // Si la imagen carga correctamente, la agregamos a la lista de imágenes válidas
                    validImages.push(image);
                    checkCompletion();
                };
    
                img.onerror = () => {
                    // Si la imagen falla, no hacemos nada y simplemente no la agregamos
                    console.log('Imagen inválida eliminada:', imageUrl);
                    checkCompletion();
                };
    
                img.src = imageUrl; // Intentamos cargar la imagen
            });
    
            const checkCompletion = () => {
                loadCount++;
                if (loadCount === this.selectedObservation!.images.length) {
                    // Una vez que se hayan procesado todas las imágenes, actualizamos el array con las válidas
                    this.selectedObservation!.images = validImages;
                }
            };
        }
    }
}