import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss'],
    standalone: true,
    imports: [BaseChartDirective]
})
export class LineChartComponent implements OnChanges {
    @Input() title: string = '';
    @Input() xData: string[] = [];
    @Input() yData: number[] = [];
    @Input() label: string = '';
    @Input() backgroundColor: string = 'rgba(75,192,192,0.4)';
    @Input() borderColor: string = 'rgba(75,192,192,1)';

    @Input() datasets: { yData: number[], label: string, backgroundColor?: string, borderColor?: string }[] = [];

    public lineChartOptions: ChartOptions<'line'> = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: ''
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return ` ${context.dataset.label}: ${context.raw}`;
                    },
                    title: (context) => {
                        return context[0].label;
                    }
                }
            },
        },
        scales: {
            x: {
                ticks: {
                    callback: (value, index, ticks) => {
                        return this.formatDate(this.xData[index]);
                    },
                    maxRotation: 45, 
                    minRotation: 45  
                }
            }
        }
    };
    public lineChartLabels: string[] = this.xData;
    public lineChartType: ChartType = 'line';
    public lineChartLegend = true;
    public lineChartPlugins = [];

    public lineChartData: ChartConfiguration<'line'>['data']['datasets'] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['title']) {
            this.lineChartOptions.plugins!.title!.text = this.title;
        }
        if (changes['xData']) {
            this.lineChartLabels = this.xData;
            this.updateChartOptions(); 
        }
        if (changes['datasets']) {
            this.lineChartData = this.datasets.map(dataset => ({
                data: dataset.yData,
                label: dataset.label,
                backgroundColor: dataset.backgroundColor || 'rgba(75,192,192,0.4)',
                borderColor: dataset.borderColor || 'rgba(75,192,192,1)',
            }));
        }
    }

    private updateChartOptions() {
        this.lineChartOptions = {
            ...this.lineChartOptions,
            scales: {
                x: {
                    ticks: {
                        callback: (value, index, ticks) => {
                            return this.formatDate(this.xData[index]);
                        },
                        maxRotation: 60,
                        minRotation: 60
                    }
                }
            }
        };
    }


    private formatDate(dateStr: string): string {
        const months: { [key: string]: string } = {
            'Enero': '01',
            'Febrero': '02',
            'Marzo': '03',
            'Abril': '04',
            'Mayo': '05',
            'Junio': '06',
            'Julio': '07',
            'Agosto': '08',
            'Septiembre': '09',
            'Octubre': '10',
            'Noviembre': '11',
            'Diciembre': '12'
        };
    
        // Extraer la parte relevante de la cadena de fecha
        const match = dateStr.match(/(\d{1,2}) de (\w+), (\d{4}) a las (\d{2}:\d{2}):\d{2}/);
        if (!match) return dateStr;
    
        const day = match[1].padStart(2, '0');
        const month = months[match[2]];
        const year = match[3].slice(-2); // Tomar los últimos dos dígitos del año
        const time = match[4];
    
        return `${day}/${month}/${year} - ${time} hrs`;
    }
}