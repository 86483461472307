import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { TrackerDevice } from '../../models/tracker-device.model';
import { Observation } from '../../models/observation.model';
import { Statistical } from '../../models/statistical.model';
import { PhotoModalComponent } from '../photo-modal/photo-modal.component';
import { ChartModalComponent } from '../chart-modal/chart-modal.component';
import { DeviceService } from '../../services/device.service';
import { OpeningsModalComponent } from "../openings-modal/openings-modal.component";
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
    standalone: true,
    selector: 'app-device-item',
    imports: [CommonModule, PhotoModalComponent, ChartModalComponent, OpeningsModalComponent, SpinnerComponent],
    templateUrl: './device-item.component.html',
    styleUrls: ['./device-item.component.scss']
})
export class DeviceItemComponent {

    constructor(private deviceService: DeviceService) {}

    isLoading = false;

    @Input() device!: TrackerDevice;
    @Input() lastObservation!: Observation;
    @Input() lastObservationFecha!: Observation;
    @Input() statisticals!: Statistical[];
    @Input() observations!: Observation[];
    @Input() mapStatistical!: { [key: number]: Statistical };
    @Input() init_yyyymmdd = -1;
    @Input() end_yyyymmdd = -1;
    
    @Output() centerMapEvent = new EventEmitter<{ lat: number, lng: number, title: String, selectedObservation: Observation }>();
    @Output() viewRouteEvent = new EventEmitter<number>();
    @Output() clearMapEvent = new EventEmitter<void>();
    @Output() toggleEvent = new EventEmitter<{ deviceId: number, isOpen: boolean }>();

    isDropdownOpen = false;
    isPhotoModalOpen = false;
    isChartModalOpen = false;
    isOpeningModalOpen = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['init_yyyymmdd'] || changes['end_yyyymmdd']) {
            //console.log('Filtros cambiaron:', changes['init_yyyymmdd'].currentValue, changes['end_yyyymmdd'].currentValue);
        }
    }

    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;

        if (!this.isDropdownOpen) {
            this.clearMapEvent.emit();
            this.toggleEvent.emit({ deviceId: this.device.id, isOpen: false });
        } else {
            this.toggleEvent.emit({ deviceId: this.device.id, isOpen: true });
            this.centerMap();
        }
    }

    closeDropdown() {
        this.isDropdownOpen = false;
        this.clearMapEvent.emit();
        this.toggleEvent.emit({ deviceId: this.device.id, isOpen: false });
    }

    centerMap() {
        if(this.lastObservation === undefined){
            return;
        }

        const lat = this.lastObservation.values ? this.lastObservation.values[3] : null;
        const lng = this.lastObservation.values ? this.lastObservation.values[4] : null; 
        const url = "https://www.polizone.mab.cl";
        const path = '/images/polizone/files/dtp/'
    
        const endpoint = url + path;
        const urlImage = endpoint + this.device.id + "/" + this.lastObservation.id + "/" + this.lastObservation.images[0];

        const title = this.lastObservation.id.toString() + ";" + this.lastObservation.registeredAtHumanFormat + ";" + this.device.id + ";" + urlImage;
        const selectedObservation = this.lastObservation;
        if (lat !== null && lng !== null) {
            this.centerMapEvent.emit({ lat, lng, title, selectedObservation });
        }
    }

    async viewPhotos() {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let observations: Observation[] = [];
        let offset = 0;
        this.isLoading = true;
        while (true) {
            const response = await this.deviceService.getObservations(token, this.device.id, -1, -1, this.init_yyyymmdd, this.end_yyyymmdd, offset).toPromise();
            const observationsList: any[] = response.data;
            if (observationsList.length === 0) {
                break;
            }

            observations = observations.concat(observationsList.map(observation => Observation.fromJson(observation)));
            offset += 1000;
        }

        /*offset = 0;
        let i = 0;
        for (const observation of observations) {
        if (observation.hasImages) {
            i++;
            const response = await this.deviceService.getObservations(token, this.device.id, -1,observation.id, init_yyyymmdd, end_yyyymmdd, offset).toPromise();
            if (response.data.length === 0) {

                continue;
            }else{
                console.log(response);
                observation.images = response.data[0].images;
            }
        }
        }*/
        this.isLoading = false;
        this.observations = observations.filter(observation => observation.images ? observation.images.length > 0 : false);
        this.observations = this.observations.reverse();
        this.isPhotoModalOpen = true;
    }

    closePhotoModal() {
        this.isPhotoModalOpen = false;
    }
    
    closeChartModal() {
        this.isChartModalOpen = false;
    }

    closeOpeningModal() {
        this.isOpeningModalOpen = false;
    }

    viewRoute() {
        this.viewRouteEvent.emit(this.device.id);
    }

    async viewCharts() {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let observations: Observation[] = [];
        let offset = 0;

        this.isLoading = true;
        while (true) {
            const response = await this.deviceService.getObservations(token, this.device.id, -1, -1, this.init_yyyymmdd, this.end_yyyymmdd, offset).toPromise();
            const observationsList: any[] = response.data;

            if (observationsList.length === 0) {
                break;
            }

            observations = observations.concat(observationsList.map(observation => Observation.fromJson(observation)));
            offset += 1000;
        }
        this.isLoading = false;

        this.observations = observations;
        this.isChartModalOpen = true;
    }

    async viewOpening(){
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }
    
        let observations: Observation[] = [];
        let offset = 0;
        
        this.isLoading = true;
        while (true) {
            const response = await this.deviceService.getObservations(token, this.device.id, -1, -1, this.init_yyyymmdd, this.end_yyyymmdd, offset).toPromise();
            const observationsList: any[] = response.data;
        
            if (observationsList.length === 0) {
                break;
            }
        
            observations = observations.concat(observationsList.map(observation => Observation.fromJson(observation)));
            offset += 1000;
        }
        this.isLoading = false;
    
        this.observations = observations;
        this.isOpeningModalOpen = true;
    }

    shareLocation() {
        // Lógica para compartir ubicación
    }
}
