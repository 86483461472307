import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
  //private apiUrl = 'http://localhost:8080/ws/mab_web_service/'; // URL de tu API
  //private apiUrl = 'https://www.test.polizone.mab.cl/ws/mab_web_service/'; // URL de tu API
  private apiUrl = 'https://www.polizone.mab.cl/ws/mab_web_service/'; // URL de tu API

    constructor(private http: HttpClient, private router: Router) { }

    login(username: string, password: string): Observable<any> {
        console.log('Trying to login')
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const hashedPassword = CryptoJS.MD5(password).toString();
        const body = {
            url: 'https://www.polizone.mab.cl/ws/mab_web_service/',
            user: username,
            password: hashedPassword,
            type: 'login'
        };

        return this.http.post<any>(this.apiUrl, body, { headers },).pipe(
            tap(response => {
                console.log(response);
                if (response.type === 'ok') {
                    const aditionalInfo = JSON.parse(response.aditional_info);
                    const token = aditionalInfo.tkn;
                    localStorage.setItem('token', token);
                }
                else {
                    throw new Error('Login failed');
                }
            })
        );
    }

    logout(): void {
        if (typeof window !== 'undefined') {
            localStorage.removeItem('token');
        }
        this.router.navigate(['/login']);
    }

    isLoggedIn(): boolean {
        if (typeof window !== 'undefined') {
            return localStorage.getItem('token') !== null;
        }
        return false;
    }
}
