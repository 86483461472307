<div class="sidebar" [class.open]="isOpen">
    <div class="sidebar-header">
        <h2>Polizone</h2>
    </div>
    <ul class="sidebar-menu">
        <li><a routerLink="/dashboard" (click)="closeSidebar()">Dashboard</a></li>
        <li><a routerLink="/profile" (click)="closeSidebar()">Perfil</a></li>
        <li><a (click)="logout()">Salir</a></li>
    </ul>
</div>
<div class="overlay" *ngIf="isOpen" (click)="closeSidebar()"></div>
