import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    private apiUrl: string;

    constructor(private http: HttpClient, private router: Router) { 
        // Define las diferentes URLs
        const devUrl = 'http://localhost:8080/ws/mab_web_service/';
        const testUrl = 'https://www.test.polizone.mab.cl/ws/mab_web_service/';
        const prodUrl = 'https://www.polizone.mab.cl/ws/mab_web_service/';

        // Elige la URL apropiada, aquí podrías hacer una selección basada en el entorno
        this.apiUrl = prodUrl; 
    }

    getProfile(token: string): Observable<any> {
        const body = {
            url: this.apiUrl,
            type: 'get_profile',
            tkn: token
        };

        return this.http.post<any>(this.apiUrl, body).pipe(
            tap(res => {
                if (res.error_code == 401) {
                    console.log('Token expired');
                    localStorage.removeItem('token');
                    this.router.navigate(['/login']);
                }
            }),
            catchError(this.handleError<any>('getProfile', []))
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }

}
