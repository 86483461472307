<app-navbar (filtersApplied)="onFiltersApplied($event)" ></app-navbar>
<div class="dashboard-content content">
    <div class="map-container-wrapper">
        <app-map class="map-container"></app-map>
    </div>
    <div class="device-list-container-wrapper">
        <app-device-list class="device-list-container" 
            (centerMapEvent)="centerMap($event.lat, $event.lng, $event.title, $event.selectedObservation)" 
            (drawRouteEvent)="drawRoute($event)" 
            (clearMapEvent)="clearMap()"
            (devicesLoadedEvent)="addDeviceMarkers($event)"
            [init_yyyymmdd]="startDate"
            [end_yyyymmdd]="endDate">
        </app-device-list>
    </div>
</div>
