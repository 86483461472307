<div class="device-item">
    <div class="device-header" (click)="toggleDropdown()">
        <i [ngClass]="{'device-toggle-icon-down': !isDropdownOpen, 'device-toggle-icon-up': isDropdownOpen}" class="material-icons device-toggle-icon">
            {{ isDropdownOpen ? 'expand_less' : 'expand_more' }}
        </i>
        <img [src]="device.isOpen ? '../../../../assets/img/Copia de icono_naranja.png' : '../../../../assets/img/Copia de icono_verde.png'" class="device-icon" alt="Icono">
        <div class="device-info">
            <span class="device-name" [ngClass]="{'open': device.isOpen, 'closed': !device.isOpen}">
                Dispositivo : {{ device.id }}
            </span>
            <span class="device-status">
            <span [ngClass]="{'open': device.isOpen, 'closed': !device.isOpen}">
                {{ device.isOpen ? 'ABIERTO' : 'CERRADO' }}
            </span>
                - {{ lastObservationFecha.registeredAtHumanFormat }}
            </span>
        </div>
    </div>
    <div class="device-details" *ngIf="isDropdownOpen">
        <div class="device-actions">
            <button (click)="viewPhotos()">
                <i class="material-icons action-icon">photo</i> Fotos
            </button>
            <button (click)="viewOpening()">
                <i class="material-icons action-icon">list</i> Lista de aperturas
            </button>
            <button (click)="viewRoute()">
                <i class="material-icons action-icon">directions_walk</i> Recorrido
            </button>
            <button (click)="viewCharts()">
                <i class="material-icons action-icon">analytics</i> Graficos
            </button>
            <!--<button (click)="shareLocation()">
                <i class="material-icons action-icon">share</i> Compartir ubicación
            </button>-->
        </div>
    </div>
    <div *ngIf="isLoading">
        <app-spinner></app-spinner>
    </div>
    <app-photo-modal 
        *ngIf="isPhotoModalOpen"
        [deviceId]="device.id"
        [observations]="observations"
        (closeModalEvent)="closePhotoModal()">
    </app-photo-modal>
    <app-chart-modal 
        *ngIf="isChartModalOpen"
        [deviceId]="device.id"
        [observations]="observations"
        [mapStatistical]="mapStatistical"
        (closeModalEvent)="closeChartModal()"
    ></app-chart-modal>
    <app-openings-modal
        *ngIf="isOpeningModalOpen"
        [deviceId]="device.id"
        [observations]="observations"
        (closeModalEvent)="closeOpeningModal()"
    ></app-openings-modal>
</div>
