import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Statistical } from '../../models/statistical.model';
import { Observation } from '../../models/observation.model';
import { OpeningsListComponent } from '../openings-list/openings-list.component';

@Component({
    selector: 'app-openings-modal',
    standalone: true,
    imports: [ CommonModule, OpeningsListComponent],
    templateUrl: './openings-modal.component.html',
    styleUrl: './openings-modal.component.scss'
})
export class OpeningsModalComponent {
    @Input() deviceId!: number;
    @Input() observations!: Observation[];
    @Input() mapStatistical!: { [key: number]: Statistical };
    @Output() closeModalEvent = new EventEmitter<void>();

    epochs: number[] = [];

    //StadisticalId = 0
    yDataLuminosity: number[] = [];
    xDataLuminosity: string[] = [];
    observationIds: number[] = [];


    ngOnInit(){
        for( const observation of this.observations){
            this.epochs.push(observation.registeredAt);
            if(observation.values[0] != 0){
                this.yDataLuminosity.push(observation.values[0])
                this.xDataLuminosity.push(observation.registeredAtHumanFormat);
                this.observationIds.push(observation.id);
            }
        }

        this.yDataLuminosity = this.yDataLuminosity.reverse();
        this.xDataLuminosity = this.xDataLuminosity.reverse();
        this.observationIds = this.observationIds.reverse();
    }


    close(): void {
        this.closeModalEvent.emit();
    }
}
