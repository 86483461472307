<nav class="navbar">
    <div class="navbar-container">
        <button class="menu-button" (click)="toggleSidebar()">
            <span class="menu-icon">=</span>
        </button>
        <div class="navbar-brand">
        </div>
        <ul class="navbar-menu">
        </ul>
        <!-- Mostrar el filtro solo si las fechas están correctamente establecidas -->
        <span *ngIf="activeFilters && activeFilters.startDate !== '-1' && activeFilters.endDate !== '-1' && activeFilters.startDate !== '' && activeFilters.endDate !== ''" class="filter-tag">
            Fecha: {{ formatToDisplayDate(activeFilters.startDate) }} - {{ formatToDisplayDate(activeFilters.endDate) }}
            <button class="remove-filter-button" (click)="removeFilter()">x</button>
        </span>
        <div class="filter-icon-container" (click)="toggleFilterModal()">
            <i class="material-icons filter-icon">filter_alt</i>
        </div>
    </div>
</nav>
<app-sidebar></app-sidebar>
<app-filter-modal 
    *ngIf="isFilterModalOpen" 
    (filtersApplied)="handleFilters($event)">
</app-filter-modal>
