import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FilterService } from '../../models/filter.model';

@Component({
    selector: 'app-filter-modal',
    standalone: true,
    imports: [FormsModule, CommonModule],
    templateUrl: './filter-modal.component.html',
    styleUrl: './filter-modal.component.scss'
})
export class FilterModalComponent {

    constructor(private filterService: FilterService) {}

    ngOnInit() {
        const savedFilters = this.filterService.getFilters();
        this.filters.startDate = savedFilters.startDate;
        this.filters.endDate = savedFilters.endDate;
        console.log(this.filters);
    }

    @Output() filtersApplied = new EventEmitter<any>(); 

    filters = {
        startDate: '',
        endDate: '',
    };

    errorMessage: string = '';

    closeModal() {
        this.filtersApplied.emit(null); 
        this.errorMessage = '';
    }
    
    applyFilters() {
        console.log(this.filters);  
        if (!this.validateDates()) {
            return;
        }

        this.filterService.setFilters(this.filters.startDate, this.filters.endDate);

        const formattedFilters = {
            startDate: this.filters.startDate ? this.formatDate(this.filters.startDate) : null,
            endDate: this.filters.endDate ? this.formatDate(this.filters.endDate) : null
        };
        this.filtersApplied.emit(formattedFilters);
        this.closeModal();
    }

    validateDates(): boolean {
        this.errorMessage = ''; // Limpiar cualquier mensaje de error previo

        if (!this.filters.startDate || !this.filters.endDate) {
            this.errorMessage = 'Por favor, seleccione ambas fechas.';
            return false;
        }

        const today = new Date();
        const startDate = this.filters.startDate ? new Date(this.filters.startDate) : null;
        const endDate = this.filters.endDate ? new Date(this.filters.endDate) : null;

        if (startDate && startDate > today) {
            this.errorMessage = "La fecha de inicio no puede ser mayor que hoy.";
            return false;
        }

        if (endDate && endDate > today) {
            this.errorMessage = "La fecha de fin no puede ser mayor que hoy.";
            return false;
        }

        if (startDate && endDate && startDate > endDate) {
            this.errorMessage = "La fecha de fin no puede ser menor que la fecha de inicio.";
            return false;
        }

        return true;
    }

    formatDate(dateString: string): number {
        const date = new Date(dateString);
        const yyyymmdd = date.toISOString().split('T')[0].replace(/-/g, '');
        return Number(yyyymmdd);
    }
}
