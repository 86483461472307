import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from '../../core/components/navbar/navbar.component';
import { SidebarComponent } from '../../core/components/sidebar/sidebar.component';
import { MapComponent } from '../../features/map/map.component';
import { DeviceListComponent } from '../../features/device-list/device-list.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { AuthService } from '../../core/services/auth.service';
import { Observation } from '../../core/models/observation.model';

@Component({
    standalone: true,
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    imports: [CommonModule, NavbarComponent, SidebarComponent, MapComponent, DeviceListComponent, GoogleMapsModule]
})
export class DashboardComponent {

    constructor(private authService: AuthService) { }

    @ViewChild(MapComponent)
    mapComponent!: MapComponent;

    startDate = -1;
    endDate = -1;

    onFiltersApplied($event: any) {
        this.startDate = $event.startDate.valueOf();
        this.endDate = $event.endDate.valueOf();
    }

    addDeviceMarkers(positions: { lat: number, lng: number, title: String, selectedObservation: Observation }[]): void {
        this.mapComponent.addMarkers(positions);
    }

    centerMap(lat: number, lng: number, title: String, selectedObservation: Observation): void {
        if (lat !== 0 && lng !== 0) {
            this.mapComponent.setCenter(lat, lng, title, selectedObservation);
        } else {
            console.warn('El dispositivo tiene una latitud o longitud de 0 y no se puede mostrar en el mapa.');
        }
    }

    drawRoute(path: { lat: number, lng: number, title: String, selectedObservation: Observation }[]): void {
        this.mapComponent.drawRoute(path);
    }

    clearMap(): void {
        this.mapComponent.clearMarkers();
        this.mapComponent.clearPath();
    }

    logout(): void {
        this.authService.logout();
    }
}
