import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-custom-alert',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './custom-alert.component.html',
    styleUrl: './custom-alert.component.scss'
})
export class CustomAlertComponent implements OnInit {
    @Input() message: string = '';
    @Input() isVisible: boolean = false;

    constructor() {}

    ngOnInit(): void {}

    closeAlert(): void {
        this.isVisible = false;
    }
}
